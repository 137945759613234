<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title :  '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <div class="section-title">
        <span>工作信息</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>姓名：</p>
          <Input v-model="name"
                 placeholder="姓名"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">工号：</p>
          <Input v-model="workNumber"
                 placeholder="工号"
                 maxlength="30"
                 v-intLimit
                 clearable></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业类型：</p>
          <Select v-model="workBigType"
                  placeholder="作业类型">
            <Option :value="item.id"
                    v-for="item in workBigTypeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li> -->
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>工种：</p>
          <Select v-model="workType"
                  placeholder="工种">
            <Option :value="item.id"
                    v-for="item in workTypeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>考勤方式：</p>
          <Select v-model="checkingIn"
                  placeholder="考勤方式">
            <Option :value="item.id"
                    v-for="item in checkingInArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">同步关联账号：</p>
          <Checkbox v-model="relevanceAccount"
                    class="form-ul-li-checkbox"
                    @on-change="onChangeRelevanceAccount"></Checkbox>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">用户账号：</p>
          <MyTreeSelect :value="account"
                        placeholder="用户账号"
                        :disabled="!relevanceAccount"
                        clearable
                        :data="userTreeArr"
                        search
                        @onSelectChange="onSelectChangeAccount">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departName"
                        placeholder="所属机构"
                        clearable
                        :data="departmentTreeArr"
                        search
                        @onSelectChange="onSelectChangeDepart">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>联系电话：</p>
          <Input v-model="phone"
                 placeholder="联系电话"
                 maxlength="30"
                 v-intLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">入职日期：</p>
          <DatePicker type="date"
                      :value="entryDate"
                      placeholder="入职日期"
                      @on-change="onChangeEntryDate"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">工资卡号：</p>
          <Input v-model="salary"
                 placeholder="工资卡号"
                 maxlength="30"
                 v-intLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">归属银行：</p>
          <Select v-model="bank"
                  placeholder="归属银行"
                  clearable>
            <Option :value="item.id"
                    v-for="item in bankArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">职位：</p>
          <Select v-model="position"
                  placeholder="职位"
                  clearable>
            <Option :value="item.id"
                    v-for="item in positionArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title">照片：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadPicture"
                        :disabled="picture">
              <li>
                <el-image :src="picture"
                          fit="cover"
                          :preview-src-list="[picture]"
                          class="w-full h-full"
                          v-show="picture">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeletePicture"
                      v-show="picture" />
                <Icon custom="i-icon icon-tupianshangchuan"
                      size="40"
                      color="#B5B5B5"
                      v-show="!picture" />
              </li>
            </UploadFile>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            图片：支持格式为png、jpg、jpeg、bmp，最多不能超过5张，最大不能超过1M；
          </div>
        </li>
        <li class="form-ul-li">
          <p class="li-title">一张网图标：</p>
          <RadioGroup v-model="icon">
            <Radio label="0"
                   :disabled="!picture">使用默认图标</Radio>
            <Radio label="1"
                   :disabled="!picture">使用人员头像</Radio>
          </RadioGroup>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 type="textarea"
                 placeholder="备注"
                 maxlength="-1"
                 :autosize="{minRows: 4,maxRows: 4}" />
        </li>
      </ul>

      <div class="section-title">
        <span>个人信息</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title">民族：</p>
          <Select v-model="nation"
                  placeholder="民族"
                  clearable>
            <Option :value="item.id"
                    v-for="item in nationArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">性别：</p>
          <RadioGroup v-model="sex">
            <Radio label="男"></Radio>
            <Radio label="女"></Radio>
          </RadioGroup>
        </li>
        <li class="form-ul-li">
          <p class="li-title">籍贯：</p>
          <Input v-model="nativePlace"
                 placeholder="籍贯"
                 maxlength="30"
                 v-stringLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">政治面貌：</p>
          <Select v-model="politics"
                  placeholder="政治面貌"
                  clearable>
            <Option :value="item.id"
                    v-for="item in politicsArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">教育程度：</p>
          <Select v-model="education"
                  placeholder="教育程度"
                  clearable>
            <Option :value="item.id"
                    v-for="item in educationArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>

        <li class="form-ul-li">
          <p class="li-title">身份证号：</p>
          <Input v-model="idNumber"
                 placeholder="身份证号"
                 maxlength="30"
                 v-stringLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">通讯地址：</p>
          <Input v-model="site"
                 placeholder="通讯地址"
                 maxlength="30"
                 v-stringLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">身份证照：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadIdPictureUp"
                        :disabled="idPictureUp">
              <li>
                <el-image :src="idPictureUp"
                          fit="cover"
                          :preview-src-list="[idPictureUp]"
                          class="w-full h-full"
                          v-show="idPictureUp">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeleteIdPictureUp"
                      v-show="idPictureUp" />
                <span v-show="!idPictureUp">(头像)</span>
              </li>
            </UploadFile>
            <UploadFile @uploadSuccess="uploadIdPictureDown"
                        :disabled="idPictureDown">
              <li>
                <el-image :src="idPictureDown"
                          fit="cover"
                          :preview-src-list="[idPictureDown]"
                          class="w-full h-full"
                          v-show="idPictureDown">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeleteIdPictureDown"
                      v-show="idPictureDown" />
                <span v-show="!idPictureDown">(国徽)</span>
              </li>
            </UploadFile>
          </ul>
        </li>
      </ul>
      <div class="section-title">
        <span>紧急联系人</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title">紧急联系人：</p>
          <Input v-model="emergencyContact"
                 placeholder="紧急联系人"
                 maxlength="30"
                 v-stringLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">联系人电话：</p>
          <Input v-model="emergencyPhone"
                 placeholder="联系人电话"
                 maxlength="30"
                 v-intLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">联系人地址：</p>
          <Input v-model="emergencySite"
                 placeholder="联系人地址"
                 maxlength="30"
                 v-stringLimit
                 clearable></Input>
        </li>
      </ul>
      <div class="section-title">
        <span>基础信息</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title">身高：</p>
          <Input v-model="height"
                 placeholder="身高"
                 maxlength="30"
                 v-intLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">cm</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">体重：</p>
          <Input v-model="weight"
                 placeholder="体重"
                 maxlength="30"
                 v-intLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">kg</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">鞋码：</p>
          <Input v-model="shoeSize"
                 placeholder="鞋码"
                 maxlength="30"
                 v-intLimit
                 clearable></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">工服尺寸：</p>
          <Select v-model="clothingSize"
                  placeholder="工服尺寸"
                  clearable>
            <Option :value="item.id"
                    v-for="item in clothingSizeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UploadFile from '@/components/common/UploadFile';
import MyTreeSelect from '@/components/common/MyTreeSelect';
export default {
  components: {
    UploadFile,
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '环卫工'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '',
      workNumber: '',
      workBigType: '',
      workType: '',
      checkingIn: '',
      checkingInArr: [
        {
          name: '定位',
          id: '定位'
        },
        {
          name: '打卡',
          id: '打卡'
        },
        {
          name: 'app',
          id: 'app'
        }
      ],
      relevanceAccount: false,
      accountId: '',
      account: '',
      departId: '',
      departName: '',
      phone: '',
      entryDate: '',
      salary: '',
      bank: '',
      position: '',
      picture: '',
      icon: '0',
      remark: '',
      nation: '',
      sex: '男',
      nativePlace: '',
      politics: '',
      education: '',
      idNumber: '',
      site: '',
      idPictureUp: '',
      idPictureDown: '',
      emergencyContact: '',
      emergencyPhone: '',
      emergencySite: '',
      height: '',
      weight: '',
      shoeSize: '',
      clothingSize: '',
    }
  },
  computed: {
    ...mapGetters({
      'nationArr': 'getNationArr',
      'bankArr': 'getBankArr',
      'politicsArr': 'getPoliticsArr',
      'educationArr': 'getEducationArr',
      'clothingSizeArr': 'getClothingSizeArr',
      'departmentTreeArr': 'getDepartmentTreeList',
      // 'workTypeArr': 'getWorkTypeAllList',
      'workTypeArr': 'getWorkTypeEnableAllList',
      'userTreeArr': 'getUserTreeList',
      // 'positionArr': 'getPositionAllList',
      'positionArr': 'getPositionEnableAllList',
      'workBigTypeArr': 'getPersonWorkBigClassAllList'
    }),
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      // 'updateWorkTypeAllList',
      'updateWorkTypeEnableAllList',
      'updateUserTreeList',
      // 'updatePositionAllList',
      'updatePositionEnableAllList',
      'updatePersonWorkBigClassAllList',
      'updatePersonTreeList'
    ]),
    init () {
      this.updateDepartmentTreeList()
      this.updateUserTreeList()
      // this.updatePositionAllList()
      this.updatePositionEnableAllList()
      this.updatePersonWorkBigClassAllList()
      // personArchives已调用，防重复调用
      // this.updateWorkTypeAllList()
      this.updateWorkTypeEnableAllList()
      setTimeout(() => {
        
        console.log(this.positionArr);
      }, 1000);
    },
    getDetail () {
      this.$store.dispatch('getPersonDetail', this.dataId).then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
        if (res.accountId) {
          this.relevanceAccount = true
        }
      })
    },
    onChangeRelevanceAccount (boo) {
      if (!boo) {
        this.accountId = ''
        this.account = ''
      }
    },
    onSelectChangeAccount (data) {
      if (data.type === 'user') {
        this.accountId = data.id
        this.account = data.name
      }
    },
    onSelectChangeDepart (data) {
      this.departId = data.id
      this.departName = data.name
    },
    onChangeEntryDate (date) {
      this.entryDate = date
    },
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.picture = arr[0].url
      }
    },
    onClickDeletePicture () {
      this.picture = ''
      this.icon = '0'
    },
    uploadIdPictureUp (arr) {
      if (arr.length > 0) {
        this.idPictureUp = arr[0].url
      }
    },
    onClickDeleteIdPictureUp () {
      this.idPictureUp = ''
    },
    uploadIdPictureDown (arr) {
      if (arr.length > 0) {
        this.idPictureDown = arr[0].url
      }
    },
    onClickDeleteIdPictureDown () {
      this.idPictureDown = ''
    },
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入姓名')
      // if (!this.workBigType) return this.$Message.info('请选择作业大类')
      if (!this.workType) return this.$Message.info('请选择工种')
      if (!this.checkingIn) return this.$Message.info('请选择考勤方式')
      if (!this.departId) return this.$Message.info('请选择所属机构')
      if (!this.phone) return this.$Message.info('请输入联系电话')
      this.loading = true
      let params = {
        name: this.name,
        workNumber: this.workNumber,
        // workBigType: this.workBigType,
        workType: this.workType,
        checkingIn: this.checkingIn,
        departId: this.departId,
        phone: this.phone,
        entryDate: this.entryDate,
        salary: this.salary,
        bank: this.bank,
        position: this.position,
        picture: this.picture,
        icon: this.icon,
        remark: this.remark,
        nation: this.nation,
        sex: this.sex,
        nativePlace: this.nativePlace,
        politics: this.politics,
        education: this.education,
        idNumber: this.idNumber,
        site: this.site,
        idPictureUp: this.idPictureUp,
        idPictureDown: this.idPictureDown,
        emergencyContact: this.emergencyContact,
        emergencyPhone: this.emergencyPhone,
        emergencySite: this.emergencySite,
        height: this.height,
        weight: this.weight,
        shoeSize: this.shoeSize,
        clothingSize: this.clothingSize,
      }
      if (this.relevanceAccount) {
        params.accountId = this.accountId
      } else {
        params.accountId = ''
      }
      if (this.dataId) params.ids = this.dataId
      this.$store.dispatch('createOrEditPerson', params).then(res => {
        this.loading = false
        this.$store.dispatch('updatePersonTreeList', true)
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.name = ''
      this.workNumber = ''
      this.workBigType = ''
      this.workType = ''
      this.checkingIn = ''
      this.relevanceAccount = false
      this.accountId = ''
      this.account = ''
      this.departId = ''
      this.departName = ''
      this.phone = ''
      this.entryDate = ''
      this.salary = ''
      this.bank = ''
      this.position = ''
      this.picture = ''
      this.icon = '0'
      this.remark = ''
      this.nation = ''
      this.sex = '男'
      this.nativePlace = ''
      this.politics = ''
      this.education = ''
      this.idNumber = ''
      this.site = ''
      this.idPictureUp = ''
      this.idPictureDown = ''
      this.emergencyContact = ''
      this.emergencyPhone = ''
      this.emergencySite = ''
      this.height = ''
      this.weight = ''
      this.shoeSize = ''
      this.clothingSize = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;

  span:first-child {
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    border-left: 2px solid #25bb96;
  }
}

.file-img {
  li {
    width: 170px;
    span {
      font-size: 16px;
      font-weight: 900;
      color: #cccccccc;
    }
  }
}
</style>
