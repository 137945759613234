<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search"
           v-if="currentTabIndex===0">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <Select v-model="workType"
                    clearable
                    placeholder="工种"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in workTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <Select v-model="workStatus"
                    clearable
                    placeholder="状态"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in workStatusArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <MyTreeSelect :value="name"
                          placeholder="姓名"
                          clearable
                          class="m-r-10"
                          style="width:160px"
                          :data="personTreeArr"
                          search
                          checked
                          @onCheckChange="onChangeName">
            </MyTreeSelect>
            <Input v-model="workNumber"
                   placeholder="工号"
                   maxlength="30"
                   v-intLimit
                   clearable
                   class="m-r-10"
                   style="width:160px" />
            <Input v-model="phone"
                   placeholder="联系电话"
                   maxlength="30"
                   v-intLimit
                   clearable
                   class="m-r-10"
                   style="width:160px" />
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/personArchives/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/personArchives/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <div class="flex">
          <template v-if="currentTabIndex===0">
            <div><Button type="primary"
                      class="m-r-10"
                      v-if="checkPermi(['/admin/personArchives/add'])"
                      @click.stop="personVisible = true">
                <Icon custom="i-icon icon-tianjia1"
                      size="16"></Icon>
                添加
              </Button>
            </div>
            <Dropdown v-if="checkPermi(['/admin/personArchives/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="part">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('part')">
                    <Button>
                      导出部分
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem>
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('now')">
                    <Button>
                      导出本页
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="all">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('all')">
                    <Button>
                      导出全部
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <ExportTemplate :path="getExportTemplatePath"
                            v-if="checkPermi(['/admin/personArchives/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出模板
              </Button>
            </ExportTemplate>
            <ReaderFile @uploadSuccess="onClickReader"
                        accept=".xls,.xlsx"
                        action='hss/tlemployee/excelReader'
                        v-if="checkPermi(['/admin/personArchives/import'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiazai"
                      size="16"></Icon>
                导入
              </Button>
            </ReaderFile>
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/personArchives/edit','/admin/personArchives/delete'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <!-- <DropdownItem name="edit">批量修改</DropdownItem> -->
                <DropdownItem name="dimission"
                              v-if="checkPermi(['/admin/personArchives/edit'])">批量离职</DropdownItem>
                <DropdownItem name="recover"
                              v-if="checkPermi(['/admin/personArchives/edit'])">批量启用</DropdownItem>
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/personArchives/delete'])">批量删除</DropdownItem>
                <DropdownItem name="setAlarm"
                              v-if="checkPermi(['/admin/personArchives/edit'])">批量设置报警参数</DropdownItem>
                <DropdownItem name="affiliation"
                              v-if="checkPermi(['/admin/personArchives/edit'])">批量修改所属机构</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </template>
          <template v-if="currentTabIndex===1">
            <div><Button type="primary"
                      class="m-r-10"
                      v-if="checkPermi(['/admin/personArchives/add'])"
                      @click.stop="personProfessionVisible=true">
                <Icon custom="i-icon icon-tianjia1"
                      size="16"></Icon>
                添加
              </Button>
            </div>
          </template>
          <template v-if="currentTabIndex===3">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/personArchives/add'])"
                    @click.stop="personPositionVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </template>
        </div>
        <template v-if="currentTabIndex===0">
          <div class="flex">
            <!-- <Button type="text">人员离职设置>></Button> -->
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}"
             v-if="currentTabIndex===0">
          <MyTree :data="departmentTreeArr"
                  style="padding:0 10px"
                  @on-select-change="onSelectChangeDepart"></MyTree>
        </div>
        <div class="table">
          <template v-if="currentTabIndex===0">
            <Table ref="table"
                   :width="tableWidth"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===0">
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/edit']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickEditPerson(row)">修改</Button>
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/edit']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickSetPerson(row)">设置</Button>
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/edit']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickStatusPerson(row)">{{row.status==1?'离职':'恢复'}}</Button>
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/delete']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickDeletePerson(row)">删除</Button>
                </template>
              </template>
            </Table>
          </template>
          <template v-else>
            <Table ref="table"
                   :width="tableWidths"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===1">
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/edit']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickEditWorkType(row)">修改</Button>
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/edit']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickStatusWorkType(row)">{{row.status==='启用'?'禁用':'恢复'}}</Button>
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/delete']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickDeleteWorkType(row)">删除</Button>
                </template>
                <template v-if="currentTabIndex===2">
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/edit']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickEditAlarm(row)">修改</Button>
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/edit']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickStatusAlarm(row)">{{row.status==1?'禁用':'恢复'}}</Button>
                </template>
                <template v-if="currentTabIndex===3">
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/edit']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickEditPosition(row)">修改</Button>
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/edit']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickStatusPosition(row)">{{row.status==='启用'?'禁用':'恢复'}}</Button>
                  <Button type="text"
                          v-hasPermi="['/admin/personArchives/delete']"
                          style="color:#25bb96;padding:0 5px"
                          @click.stop="onClickDeletePosition(row)">删除</Button>
                </template>
              </template>
            </Table>
          </template>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>

    <!-- 新增环卫工 -->
    <PersonModal :dataId.sync="personId"
                 v-model="personVisible"
                 @onClickConfirm="getList"
                 @onChange='onChangClearRowStyle'></PersonModal>

    <!-- 新增环卫工种 -->
    <PersonProfessionModal v-model="personProfessionVisible"
                           :dataId.sync="personProfessionId"
                           @onClickConfirm="getList"
                           @onChange='onChangClearRowStyle'></PersonProfessionModal>

    <!-- 新增职位 -->
    <PersonPositionModal v-model="personPositionVisible"
                         :dataId.sync="personPositionId"
                         @onClickConfirm="getList"
                         @onChange='onChangClearRowStyle'></PersonPositionModal>

    <!-- 人员档案弹框 -->
    <PersonDetailModal v-model="personDetailVisible"
                       :dataId.sync="personDetailId"
                       @onChange='onChangClearRowStyle'></PersonDetailModal>
    <!-- 批量所属机构 -->
    <PersonAffiliationModal :dataId.sync="personAffiliationId"
                            v-model="personAffiliationVisible"
                            @onClickConfirm="getList"></PersonAffiliationModal>
    <!-- 绑定设备 -->
    <BindEquipmentModal v-model="bindEquipmentVisible"
                        :item.sync="bindEquipmentObject"
                        @onClickConfirm="getList"
                        @onChange='onChangClearRowStyle'></BindEquipmentModal>

    <!-- 离职 -->
    <PersonDimissionModal :dataId.sync="personId"
                          v-model="personDimissionVisible"
                          @onClickConfirm="getList" @onChange='onChangClearRowStyle'></PersonDimissionModal>

    <!-- 点击设置，修改人员报警参数 -->
    <ParameterListModal :dataId.sync="personParameterId"
                        v-model="personParameterVisible"
                        thresholdType="person"
                        @onChange='onChangClearRowStyle'></ParameterListModal>

    <!-- 报警参数tab中的修改按钮 -->
    <ParameterModal v-model="personDetailParameterVisible"
                    :type="personDetailParameterType"
                    :dataId.sync="personDetailParameterDataId"
                    thresholdType="person"
                    @onClickConfirm="getList"
                    @onChange='onChangClearRowStyle'></ParameterModal>

    <!-- 绑定围栏 -->
    <BindEnclosureModal v-model="bindEnclosureVisible"
                        :item.sync="bindEnclosureObject"
                        objectType="emp"
                        @onClickConfirm="getList"
                        @onChange='onChangClearRowStyle'></BindEnclosureModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import MyTree from '@/components/common/MyTree';
import PersonModal from '@/components/product/admin/modal/archives/PersonModal'
import PersonProfessionModal from '@/components/product/admin/modal/archives/PersonProfessionModal'
import PersonPositionModal from '@/components/product/admin/modal/archives/PersonPositionModal'
import PersonDetailModal from '@/components/product/admin/modal/archives/PersonDetailModal'
import BindEquipmentModal from '@/components/product/admin/modal/archives/BindEquipmentModal'
import BindEnclosureModal from '@/components/product/admin/modal/archives/BindEnclosureModal'
import PersonDimissionModal from '@/components/product/admin/modal/archives/PersonDimissionModal'
import PersonAffiliationModal from '@/components/product/admin/modal/archives/PersonAffiliationModal'
import ParameterListModal from '@/components/product/admin/modal/archives/ParameterListModal'
import ParameterModal from '@/components/product/admin/modal/archives/ParameterModal'
import ReaderFile from '@/components/common/ReaderFile'
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
import { download } from '@/utils/utils'
export default {
  components: {
    MyTreeSelect,
    MyTree,
    PersonModal,
    PersonProfessionModal,
    PersonPositionModal,
    PersonDetailModal,
    BindEquipmentModal,
    BindEnclosureModal,
    PersonDimissionModal,
    PersonAffiliationModal,
    ParameterListModal,
    ParameterModal,
    ReaderFile,
    ExportTemplate
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '环卫工列表',
        },
        {
          name: '环卫工种',
        },
        {
          name: '报警参数设置',
        },
        {
          name: '职位设置',
        },
      ],
      workType: '',
      workStatus: '',
      workStatusArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '在职'
        },
        {
          id: 2,
          name: '离职'
        },
      ],
      nameId: '',
      name: '',
      workNumber: '',
      phone: '',
      departId: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableWidths: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsList: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'name',
          width: 90,
          tooltip: true,
          fixed: 'left',
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickPersonDetail(row)
                }
              }
            }, row.name);
          }
        },
        {
          title: '所属机构',
          key: 'empldeptname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '工号',
          key: 'emplnum',
          width: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '考勤方式',
          key: 'attendtype',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '工种',
          key: 'worktypename',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '作业大类',
        //   key: 'persontypename',
        //   width: 90,
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '性别',
          key: 'sex',
          width: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '在职状态',
          key: 'status',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', [
              h('i', {
                style: {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '2px',
                  background: row.status == 1 ? '#25bb96' : '#fa5357'
                }
              }),
              h('span', row.status == 1 ? '在职' : '离职')
            ]);
          }
        },
        {
          title: '图片',
          key: 'personphoto',
          tooltip: true,
          width: 60,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.personphoto) {
              let arr = row.personphoto.split(',').filter(item => item)
              if (arr.length) {
                return h('el-image', {
                  props: {
                    src: arr[0],
                    'preview-src-list': arr
                  },
                  style: {
                    width: '22px',
                    height: '22px',
                    cursor: 'pointer'
                  }
                })
              }
            }
            return h('span', '')
          }
        },
        {
          title: '联系电话',
          key: 'phonenum',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '绑定设备数',
          key: 'binds',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindEquipment(row)
                }
              }
            }, row.binds ? row.binds.length : '点击绑定');
          }
        },
        {
          title: '电子围栏',
          key: 'bindfaclids',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindEnclosure(row)
                }
              }
            }, row.gridBindList.length > 0 ? row.gridBindList[0].gridName : '点击绑定');
          }
        },
        {
          title: '备注',
          key: 'remark',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsType: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '人员工种',
          key: 'worktype',
          width: 140,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '驾驶员',
          key: 'isdriver',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '地图监控图标(在线)',
          key: 'icon',
          width: 140,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.icon) {
              return h('img', {
                attrs: {
                  src: require('../../../assets/common/person/' + row.icon + '_in.png')
                },
                style: {
                  width: '26px',
                  height: '26px',
                }
              });
            } else {
              return h('span', '')
            }
          }
        },
        {
          title: '地图监控图标(离线)',
          key: 'icon',
          width: 140,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.icon) {
              return h('img', {
                attrs: {
                  src: require('../../../assets/common/person/' + row.icon + '_out.png')
                },
                style: {
                  width: '26px',
                  height: '26px',
                }
              });
            } else {
              return h('span', '')
            }
          }
        },
        {
          title: '地图监控图标(报警)',
          key: 'icon',
          width: 140,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.icon) {
              return h('img', {
                attrs: {
                  src: require('../../../assets/common/person/' + row.icon + '_alarm.png')
                },
                style: {
                  width: '26px',
                  height: '26px',
                }
              });
            } else {
              return h('span', '')
            }
          }
        },
        {
          title: '启用状态',
          key: 'status',
          width: 140,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == '启用') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已停用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsAlarm: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '系统名称',
          key: 'sysname',
          width: 140,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警名称',
          key: 'alarmname',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '阈值',
          key: 'threshold',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已停用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      columnsPosition: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '职位名称',
          key: 'position',
          width: 200,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          width: 200,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == '启用') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已停用')
          }
        },
        {
          title: '职位介绍',
          key: 'positionintroduce',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 新增环卫工
      personVisible: false,
      personId: '',
      // 离职
      personId: '',
      personDimissionVisible: false,
      // 新增环卫工种
      personProfessionVisible: false,
      personProfessionId: '',
      // 新增职位
      personPositionVisible: false,
      personPositionId: '',
      // 人员档案
      personDetailVisible: false,
      personDetailId: '',
      // 所属机构
      personAffiliationVisible: false,
      personAffiliationId: '',
      // 绑定设备
      bindEquipmentVisible: false,
      bindEquipmentObject: null,
      // 绑定围栏
      bindEnclosureVisible: false,
      bindEnclosureObject: null,
      // 表格参数设置
      personParameterVisible: false,
      personParameterId: '',
      // 参数设置
      personDetailParameterVisible: false,
      personDetailParameterDataId: '',
      personDetailParameterType: ''
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'personTreeArr': 'getPersonTreeList',
    }),
    workTypeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getWorkTypeAllList)
    },
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsList
          break;
        case 1:
          columns = this.columnsType
          break;
        case 2:
          columns = this.columnsAlarm
          break;
        case 3:
          columns = this.columnsPosition
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportPerson
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportPersonTem
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updatePersonTreeList',
      'updateWorkTypeAllList',
      'updatePositionAllList',
      // 'updateWorkTypeEnableAllList'
    ]),
    init () {
      this.tableWidths = window.innerWidth - 240
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidths = window.innerWidth - 240
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 350
      }
      this.updateDepartmentTreeList()
      this.updatePersonTreeList()
      this.updateWorkTypeAllList()
      this.updatePositionAllList()
      // this.updateWorkTypeEnableAllList()
      this.getList()
    },
    // 修改姓名
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'user') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      // this.nameId = idArr.join()
      this.name = valueArr.join()
    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 批量操作
    onClickBatch (name) {
      if (this.selectArr.length == 0) return this.$Message.info('请选择数据')
      switch (name) {
        case 'delete':
          this.onDeletePerson(this.selectArr)
          break;
        // 离职
        case 'dimission':
          this.personId = this.selectArr.join()
          this.personDimissionVisible = true
          break;
        // 所属机构
        case 'affiliation':
          this.personAffiliationId = this.selectArr.join()
          this.personAffiliationVisible = true
          break;
        // 启用
        case 'recover':
          this.$Modal.confirm({
            title: '启用',
            content: '确认启用?',
            onOk: () => {
              let params = {
                ids: this.selectArr.join(),
                status: 1
              }
              this.$store.dispatch('createOrEditPerson', params).then(res => {
                this.getList()
                // 更新人员树列表
                this.$store.dispatch('updatePersonTreeList', true)
              }).catch(err => {
                this.$Message.info(err.message)
              })
            }
          })
          break;
        case 'setAlarm':
          this.personParameterId = this.selectArr.join()
          this.personParameterVisible = true
          break;
        default:
          break;
      }
    },
    // tab多选
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    // 选择部门
    onSelectChangeDepart (section, data) {
      this.departId = data.id
      this.onClickSearch()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 获取列表
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getPersonList()
          break;
        case 1:
          this.getWorkTypeList()
          break;
        case 2:
          this.getAlarmList()
          break;
        case 3:
          this.getPositionList()
          break;
        default:
          break;
      }
    },
    // 获取人员列表
    getPersonList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        page: this.pageNum,
        size: this.pageSize,
        status: '',
        employeeName: this.name,
        employeeNumber: this.workNumber,
        phoneNumber: this.phone,
        deptId: '',
        empldeptid: this.departId,
        workType: ''
      }
      if (this.workType) params.workType = this.workType
      if (this.workStatus) params.status = this.workStatus == 1 ? 1 : 0
      this.tableLoading = true
      this.$http.getPersonList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取工种列表
    getWorkTypeList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize
      }
      this.tableLoading = true
      this.$http.getWorkTypeList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.worktypelist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取报警列表
    getAlarmList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        thresholdtype: 'person'
      }
      this.tableLoading = true
      this.$http.getAlarmList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.thresholdlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取职位列表
    getPositionList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize
      }
      this.tableLoading = true
      this.$http.getPositionList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.positionlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.selectArr = []
      this.workType = ''
      this.workStatus = ''
      // this.nameId = ''
      this.name = ''
      this.workNumber = ''
      this.phone = ''
      this.departId = ''
    },
    // 点击人员详情
    onClickPersonDetail (row) {
      this.personDetailVisible = true
      this.personDetailId = row.id
      this.selectVisitedArr.push(row)
    },
    // 点击绑定设备
    onClickBindEquipment (row) {
      this.selectVisitedArr.push(row)
      row.type = 'emp'
      row.item = row.worktype
      this.bindEquipmentObject = row
      this.bindEquipmentVisible = true
    },
    // 点击绑定电子围栏
    onClickBindEnclosure (row) {
      this.selectVisitedArr.push(row)
      this.bindEnclosureObject = row
      this.bindEnclosureVisible = true
    },
    // 点击修改人员
    onClickEditPerson (row) {
      this.selectVisitedArr.push(row)
      this.personId = row.id
      this.personVisible = true
    },
    // 点击设置参数
    onClickSetPerson (row) {
      this.selectVisitedArr.push(row)
      this.personParameterId = row.id
      this.personParameterVisible = true
    },
    // 点击删除人员
    onClickDeletePerson (row) {
      this.selectVisitedArr.push(row)
      this.onDeletePerson([row.id])
    },
    // 修改人员状态
    onClickStatusPerson (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 1) {
        this.personId = row.id
        this.personDimissionVisible = true
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              ids: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditPerson', params).then(res => {
              this.getList()
              // 更新人员树列表
              this.$store.dispatch('updatePersonTreeList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 删除记录
    onDeletePerson (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '是否解绑设备并删除?',
        onOk: () => {
          let params = {
            ids: arr.join()
          }
          this.$http.deletePerson(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.selectArr = []
              this.getList()
              // 更新人员树列表
              this.$store.dispatch('updatePersonTreeList', true)
            }
          })
          this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 点击修改工种
    onClickEditWorkType (row) {
      this.personProfessionId = row.id
      this.personProfessionVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击删除工种
    onClickDeleteWorkType (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deletePersonProfession(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
              // 更新工种全部列表
              this.$store.dispatch('updateWorkTypeEnableAllList', true)
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改工种状态
    onClickStatusWorkType (row) {
      this.selectVisitedArr.push(row)
      if (row.status === '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditPersonProfession', params).then(res => {
              this.getList()
              // 更新工种全部列表
              this.$store.dispatch('updateWorkTypeEnableAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditPersonProfession', params).then(res => {
              this.getList()
              // 更新工种全部列表
              this.$store.dispatch('updateWorkTypeEnableAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 修改报警参数
    onClickEditAlarm (row) {
      this.selectVisitedArr.push(row)
      this.personDetailParameterVisible = true
      this.personDetailParameterType = row.sysname
      this.personDetailParameterDataId = row.id
    },
    // 修改报警参数状态
    onClickStatusAlarm (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              status: 0,
              thresholdtype: 'person',
              name: row.alarmname,
              sysname: row.sysname,
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              status: 1,
              thresholdtype: 'person',
              name: row.alarmname,
              sysname: row.sysname,
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 修改职位
    onClickEditPosition (row) {
      this.selectVisitedArr.push(row)
      this.personPositionId = row.id
      this.personPositionVisible = true
    },
    // 删除职位
    onClickDeletePosition (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deletePosition(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.getList()
              this.$store.dispatch('updatePositionEnableAllList', true)
            }
          })
          this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改职位状态
    onClickStatusPosition (row) {
      this.selectVisitedArr.push(row)
      if (row.status === '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditPosition', params).then(res => {
              this.getList()
              this.$store.dispatch('updatePositionEnableAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditPosition', params).then(res => {
              this.getList()
              this.$store.dispatch('updatePositionEnableAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            worktype: '',
            status: '',
            name: this.name,
            emplnum: this.workNumber,
            phonenum: this.phone,
            deptid: this.departId
          }
          if (this.facilityBigType) params.facltypeid = this.facilityBigType
          if (this.facilitySmallType) params.facltypeitemid = this.facilitySmallType
          if (this.status) params.faclstatus = this.status - 1
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
      .tree-title {
        background: #f5f5f5;
        font-size: 14px;
        color: #25bb96;
        padding: 15px 0 15px 12px;
        font-weight: bold;
      }
      .tree-list {
        .tree-item {
          padding: 0 8px;
          line-height: 36px;
          cursor: pointer;
          &:hover {
            background: #f0f3f7;
          }
          &.active {
            background: #f0f3f7;
          }
        }
      }
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>