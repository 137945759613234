<template>
  <Modal :value="visible"
         :title="title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departName"
                        placeholder="所属机构"
                        clearable
                        :data="departmentTreeArr"
                        search
                        @onSelectChange="onSelectChangeDepart">
          </MyTreeSelect>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MyTreeSelect from "@/components/common/MyTreeSelect";
export default {
  components: {
    MyTreeSelect,
  },
  model: {
    prop: "visible",
    event: "onChange",
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "修改所属机构",
    },
    dataId: String,
  },
  data () {
    return {
      loading: false,
      relevanceAccount: false,
      departName: "",
    };
  },
  computed: {
    ...mapGetters({
      departmentTreeArr: "getDepartmentTreeList",
    }),
  },
  watch: {
    dataId (newVal) {
      // newVal && this.getDetail()
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    ...mapActions(["updateDepartmentTreeList"]),
    init () {
      this.updateDepartmentTreeList();
    },
    getDetail () {
      this.$store.dispatch("getPersonDetail", this.dataId).then((res) => {
        for (const i in res) {
          this[i] = res[i];
        }
        if (res.accountId) {
          this.relevanceAccount = true;
        }
      });
    },
    onSelectChangeDepart (data) {
      this.departId = data.id;
      this.departName = data.name;
    },
    onClickConfirm () {
      if (!this.departId) return this.$Message.info("请选择所属机构");
      this.loading = true;
      let params = {
        departId: this.departId,
      };
      if (this.dataId) params.ids = this.dataId;
      this.$store.dispatch("createOrEditPerson", params).then((res) => {
        this.loading = false;
        this.$emit("onClickConfirm");
        this.$emit("onChange", false);
      }).catch((err) => {
        this.loading = false;
      });
    },
    onClickCancel () {
      this.$emit("onClickCancel");
      this.$emit("onChange", false);
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData();
        this.$emit("onChange", false);
        this.$emit("update:dataId", "");
      }
    },
    resetData () {
      this.departId = "";
      this.departName = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;

  span:first-child {
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    border-left: 2px solid #25bb96;
  }
}

.file-img {
  li {
    width: 170px;
    span {
      font-size: 16px;
      font-weight: 900;
      color: #cccccccc;
    }
  }
}
</style>
