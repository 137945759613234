<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>职位：</p>
          <Input v-model="position"
                 placeholder="职位"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">职位介绍：</p>
          <Input v-model="positionIntroduce"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '职位'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      position: '',
      positionIntroduce: '',
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.$store.dispatch('getPositionDetail', this.dataId).then(res => {
        this.position = res.position
        this.positionIntroduce = res.positionIntroduce
      })
    },
    onClickConfirm () {
      if (!this.position) return this.$Message.info('请输入职位');
      this.loading = true
      let params = {
        position: this.position,
        positionIntroduce: this.positionIntroduce
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditPosition', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.$store.dispatch('updatePositionAllList', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.position = ''
      this.positionIntroduce = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
