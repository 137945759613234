<template>
  <Drawer :title="title"
          closable
          width="45"
          :value="visible"
          @on-visible-change="onChangeVisible">
    <div class="detail"
         v-if="data">
      <div class="detail-top">
        <div class="detail-top-title">
          <Icon custom="i-icon icon-renyuanshixin"
                size="32"
                color="#ffffff"
                class="detail-top-title-icon" />
          <div class="detail-top-title-div">
            <p>{{data.name || '--'}}</p>
            <div><span>所属部门：</span>{{data.departName || '--'}}</div>
          </div>
        </div>
        <div class="detail-top-msg">
          <p>在职状态：<span>{{data.status || '--'}}</span></p>
          <p>联系电话：<span>{{data.phone || '--'}}</span></p>
          <p>工号：<span>{{data.workNumber ||'--'}}</span></p>
        </div>
        <div>
          <el-image :src="data.picture"
                    fit="cover"
                    :preview-src-list="[data.picture]"
                    class="detail-top-img"
                    v-if="data.picture">
          </el-image>
          <span v-else>暂无图片</span>
        </div>
      </div>
      <Tabs>
        <TabPane label="基础概况"></TabPane>
      </Tabs>
      <div>
        <div class="section">
          <div class="section-title">
            <span>工作信息</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>入职日期:</label>
              <span>{{data.entryDate || '--'}}</span>
            </li>
            <!-- <li>
              <label>工龄:</label>
              <span>{{data.workTime || '--'}}</span>
            </li> -->
            <template v-if="data.status==='离职'">
              <li>
                <label>离职日期:</label>
                <span>{{data.dimissionDate || '--'}}</span>
              </li>
              <li>
                <label>离职原因:</label>
                <span>{{data.dimission || '--'}}</span>
              </li>
            </template>
            <li>
              <label>工资卡号:</label>
              <span>{{data.salary || '--'}}</span>
            </li>
            <li>
              <label>归属银行:</label>
              <span>{{data.bank || '--'}}</span>
            </li>
            <li>
              <label>职位:</label>
              <span>{{data.position || '--'}}</span>
            </li>
            <li class="one-row">
              <label>备注:</label>
              <span>{{data.remark || '--'}}</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <div class="section-title">
            <span>个人信息</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>姓名:</label>
              <span>{{data.name || '--'}}</span>
            </li>
            <li>
              <label>性别:</label>
              <span>{{data.sex || '--'}}</span>
            </li>
            <li>
              <label>籍贯:</label>
              <span>{{data.nativePlace || '--'}}</span>
            </li>
            <li>
              <label>年龄:</label>
              <span>{{data.age || '--'}}</span>
            </li>
            <li>
              <label>民族:</label>
              <span>{{data.nation || '--'}}</span>
            </li>
            <li>
              <label>政治面貌:</label>
              <span>{{data.politics || '--'}}</span>
            </li>
            <li>
              <label>身份证号:</label>
              <span>{{data.idNumber || '--'}}</span>
            </li>
            <li>
              <label>通讯地址:</label>
              <span>{{data.site || '--'}}</span>
            </li>
            <li class="img-row">
              <label>身份证照:</label>
              <el-image :src="data.idPictureUp"
                        fit="cover"
                        :preview-src-list="[data.idPictureUp]"
                        v-if="data.idPictureUp"
                        class="m-r-10">
              </el-image>
              <span v-else>暂无身份证正面图片</span>
              <el-image :src="data.idPictureDown"
                        fit="cover"
                        :preview-src-list="[data.idPictureDown]"
                        v-if="data.idPictureDown">
              </el-image>
              <span v-else>暂无身份证反面图片</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <div class="section-title">
            <span>紧急联系人</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>联系人:</label>
              <span>{{data.emergencyContact || '--'}}</span>
            </li>
            <li>
              <label>联系电话:</label>
              <span>{{data.emergencyPhone || '--'}}</span>
            </li>
            <li class="one-row">
              <label>通讯地址:</label>
              <span>{{data.emergencySite || '--'}}</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <div class="section-title">
            <span>劳保信息</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>身高:</label>
              <span>{{data.height || '--'}}</span>
            </li>
            <li>
              <label>体重:</label>
              <span>{{data.weight || '--'}}</span>
            </li>
            <li>
              <label>鞋码:</label>
              <span>{{data.shoeSize || '--'}}</span>
            </li>
            <li>
              <label>工服尺寸:</label>
              <span>{{data.clothingSize || '--'}}</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <div class="section-title">
            <span>附件资料</span>
          </div>
          <div>
            <div class="noData">
              <img src="../../../../../assets/common/biaogeno.png">
              <p>暂无数据</p>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">
            <span>绑定设备</span>
          </div>
          <div>
            <Table ref="equipmentTable"
                   :columns="columnsEquipmentList"
                   :data="tableEquipmentData"
                   border
                   stripe
                   :loading="tableEquipmentLoading">
            </Table>
            <!-- <div class="noData">
              <img src="../../../../../assets/common/biaogeno.png">
              <p>暂无数据</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "人员档案"
    },
    dataId: String
  },
  data () {
    return {
      data: {},
      columnsEquipmentList: [
        {
          title: '设备编号',
          key: 'equipcode',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备类型',
          key: 'equiptypeid',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备通讯号',
          key: 'communicationcode',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '厂商',
          key: 'manufacturerid',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        }
      ],
      tableEquipmentData: [],
      tableEquipmentLoading: false,
      totalPage: 0,
      pageNum: 1,
      pageSize: 99999,
    };
  },
  watch: {
    dataId (newValue) {
      if (newValue) {
        this.getDetail()
        this.getEquipmentList()
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getPersonDetail', this.dataId).then(res => {
        this.data = res
        switch (this.data.status) {
          case '0':
            this.data.status = '离职'
            break;
          case '1':
            this.data.status = '在职'
            break;
          default:
            break;
        }
        // this.getVisitorsFlowrate()
        this.getEquipmentList()
      })
    },
    getEquipmentList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        bindtype: 'emp',
        bindobjid: this.dataId,
      }
      this.$http.getEquipmentList(params).then(res => {
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.tableEquipmentData = res.result.equiplist
        }
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('update:dataId', '')
        this.$emit('onChange', false)
      }
    },
    resetData () {
      this.data = {}
    }
  },
}
</script>

<style lang='scss' scoped>
.detail {
  .detail-top {
    padding: 8px 27px 0;
    .detail-top-title {
      display: flex;
      margin-bottom: 10px;
      .detail-top-title-icon {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #0079fe;
        text-align: center;
        line-height: 50px;
        margin-right: 7px;
      }
      .detail-top-title-div {
        color: #333;
        font-size: 14px;
        p {
          font-size: 18px;
        }
        div {
          color: #6a6b75;
        }
      }
    }
    .detail-top-msg {
      display: flex;
      font-size: 13px;
      color: #333;
      span {
        color: #0079fe;
        padding-right: 45px;
      }
    }
    .detail-top-img {
      width: 170px;
      height: 108px;
    }
  }
  .section {
    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;

      span:first-child {
        font-weight: 700;
        // font-size: 16px;
        padding-left: 10px;
        border-left: 2px solid #25bb96;
      }
    }
    .section-cont {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 15px;
      li {
        width: 50%;
        line-height: 34px;
        label {
          display: inline-block;
          min-width: 100px;
          text-align: right;
          margin-right: 10px;
        }
      }
      .one-row {
        width: 100%;
      }
      .img-row {
        display: flex;
        align-items: flex-start;
        img {
          width: 50%;
          margin-right: 10px;
        }
        span {
          margin-right: 10px;
        }
      }
    }
    .noData {
      p {
        color: #000;
      }
    }
  }
}
</style>