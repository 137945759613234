<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>工种：</p>
          <Input v-model="name"
                 placeholder="工种"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox" v-model="status"></Checkbox>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>监控图标：</p>
          <Select v-model="icon"
                  placeholder="监控图标"
                  @on-change="onChangeIcon">
            <img :src="getIconUrl"
                 slot="prefix"
                 v-if="icon"
                 style="width:30px;height:30px" />
            <Option :value="item.id"
                    v-for="item in iconArr"
                    :key="item.id">
              <img :src="item.name">
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">驾驶员：</p>
          <Checkbox class="form-ul-li-checkbox" v-model="driver"></Checkbox>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="icon-div">
            <div class="icon-div-item">
              <div>
                <img :src="onLineIcon"
                     v-show="onLineIcon">
              </div>
              <p class="li-title">在线</p>
            </div>
            <div class="icon-div-item">
              <div>
                <img :src="offLineIcon"
                     v-show="offLineIcon">
              </div>
              <p class="li-title">离线</p>
            </div>
            <div class="icon-div-item">
              <div>
                <img :src="policeIcon"
                     v-show="policeIcon">
              </div>
              <p class="li-title">报警</p>
            </div>
          </div>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '人员工种'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '',
      status: true,
      icon: '',
      driver: false,
      remark: '',
      onLineIcon: '',
      offLineIcon: '',
      policeIcon: '',
      typeArr: [
        {
          name: '转入',
          value: 'shiftTo'
        }, {
          name: '转出',
          value: 'rollOut'
        }, {
          name: '无',
          value: 'without'
        }
      ],
      iconArr: [
        {
          id: 'driver',
          name: require('../../../../../assets/common/person/driver_in.png')
        },
        {
          id: 'other',
          name: require('../../../../../assets/common/person/other_in.png')
        },
        {
          id: 'patrol',
          name: require('../../../../../assets/common/person/patrol_in.png')
        },
        {
          id: 'people',
          name: require('../../../../../assets/common/person/people_in.png')
        }
      ],
    }
  },
  computed: {
    getIconUrl () {
      return this.iconArr.find(item => item.id === this.icon).name
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  methods: {
    ...mapActions([
      'updateWorkTypeAllList'
    ]),
    // 获取工种详情
    getDetail () {
      this.$store.dispatch('getPersonProfessionDetail', this.dataId).then(res => {
        this.name = res.name
        this.icon = res.icon
        this.driver = res.driver == 0 ? false : true
        this.status = res.status == 0 ? false : true
        this.onChangeIcon(this.icon)
      })
    },
    // 监控图标更改
    onChangeIcon (value) {
      if (value) {
        this.onLineIcon = require('../../../../../assets/common/person/' + value + '_in.png')
        this.offLineIcon = require('../../../../../assets/common/person/' + value + '_out.png')
        this.policeIcon = require('../../../../../assets/common/person/' + value + '_alarm.png')
      }
    },
    // 确认
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入工种');
      if (!this.icon) return this.$Message.info('请选择监控图标');
      this.loading = true
      let params = {
        name: this.name,
        driver: this.driver ? 1 : 0,
        icon: this.icon,
        status: this.status ? 1 : 0,
        remark: this.remark
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditPersonProfession', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        // 更新工种全部列表
        this.$store.dispatch('updateWorkTypeAllList', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.name = ''
      this.status = true
      this.icon = ''
      this.driver = false
      this.remark = ''
      this.onLineIcon = ''
      this.offLineIcon = ''
      this.policeIcon = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-div {
  display: flex;
  // text-align: center;
  .icon-div-item {
    div {
      width: 45px;
      height: 45px;
      background: #f0f3f6;
      margin: 0 auto;
      border: 1px solid #b3b0b0;
      border-radius: 3px;
      line-height: 45px;
      // text-align: center;
      img {
        width: 100%;
        height: 100%;
        // margin-right: 30px;
      }
    }
    p {
      text-align: center !important;
      margin: 5px 0 0 0;
    }
  }
}
</style>
