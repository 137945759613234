<template>
  <Modal :value="visible"
         :title="title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>离职原因：</p>
          <Cascader :data="cascaderData"
                    transfer
                    v-model="dimission"></Cascader>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '离职'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      dimission: [],
      cascaderData: [{
        value: '主动原因',
        label: '主动原因',
        children: [
          {
            value: '个人原因',
            label: '个人原因'
          },
          {
            value: '家庭原因',
            label: '家庭原因'
          },
          {
            value: '发展原因',
            label: '发展原因'
          },
          {
            value: '合同到期不续签',
            label: '合同到期不续签'
          },
          {
            value: '其他',
            label: '其他'
          }
        ]
      }, {
        value: '被动原因',
        label: '被动原因',
        children: [
          {
            value: '协议解除',
            label: '协议解除'
          },
          {
            value: '无法胜任工作',
            label: '无法胜任工作'
          },
          {
            value: '经济型裁员',
            label: '经济型裁员'
          },
          {
            value: '严重违法违纪',
            label: '严重违法违纪'
          },
          {
            value: '其他',
            label: '其他'
          }
        ],
      }]
    }
  },
  methods: {
    onClickConfirm () {
      if (this.dimission.length === 0) return this.$Message.info('请输入离职原因');
      this.loading = true
      let params = {
        ids: this.dataId,
        status: 0,
        dimission: this.dimission[0] + '-' + this.dimission[1]
      }
      this.$store.dispatch('createOrEditPerson', params).then(res => {
        this.$store.dispatch('updatePersonTreeList', true)
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.position = ''
      this.positionIntroduce = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
